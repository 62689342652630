





































































































































































































































import { promiseHelper } from '@/helper/promise-helper'
import { CommentModel } from '@/models/post-model'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { debounce } from 'lodash'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    PostComment: () => import('@/modules/community/common/components/post-comment.vue'),
    'comment-translate-content': () => import('@/modules/community/common/components/comment-translate-content.vue'),
    Avatar: () => import('@/modules/community/common/components/avatar.vue'),
  },
})
export default class extends Vue {
  @Prop({ default: {} }) postStore!: PostStore
  @Prop({}) focusedComment?: string
  walletStore = walletStore

  get avaSize() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 50
    } else {
      return 40
    }
  }

  cancelComment() {
    const arr = this.$refs[`postComment-${this.postStore.post.id}`] as any
    arr.unfocus()
    this.postStore.cancelComment()
  }

  async requestEdit(comment: CommentModel) {
    comment.editing = true
    this.postStore.requestEditComment(comment)
    const arr = this.$refs[`postComment-${this.postStore.post.id}`] as any
    arr.requestEdit(comment)
  }

  cancelReply(comment: CommentModel) {
    comment.replying = false
    comment.replyingMentionUsers = []
    comment.replyingText = ''
  }

  async requestReplyTo(comment: CommentModel, outterComment: CommentModel | null = null) {
    if (outterComment) {
      outterComment.replying = true
    } else {
      comment.replying = true
    }
    await promiseHelper.delay(200)
    ;(this.$refs[`postComment-${(outterComment || comment).id}`] as any)[0].requestFocus(comment.owner)
  }

  async requestReply() {
    // if (!this.walletStore.verifyUserAction()) return
    await promiseHelper.delay(200)
    // if (this.postStore.isEnableComment) {
    const arr = this.$refs[`postComment-${this.postStore.post.id}`] as any
    arr?.requestFocus()
    // }
  }

  @Watch('focusedComment', { immediate: true })
  onCommentFocusing(value) {
    if (value) {
      let flag = false
      const interval = setInterval(
        debounce(() => {
          const element = document.getElementById(`comment-${value}`)
          if (element) {
            document.getElementById(`comment-${value}`)?.scrollIntoView({ block: 'center', inline: 'center' })
          }
          flag = true
          if (flag) clearInterval(interval)
        }, 200),
        300
      )
    }
  }
}
